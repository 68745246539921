import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}>{`Area I was opened during the 2016 field season as a southward extension of Area C, where excavations have revealed a Republican domus and evidence of habitation dating back to the Iron/Bronze Age. Initial excavations in Area I revealed the eastern extent of an Imperial domus facing the Via Gabina, one of Gabii’s main thoroughfares. Over the course of the 2017 and 2018 field seasons, the excavation limit was extended westward to expose more of this building, although further investigation will be needed to reveal the structure’s western limit.`}</MDXTag>
<MDXTag name="p" components={components}>{`After a Late Republican phase, attested by the presence of patches of cocciopesto floors and opus reticulatum walls, a large Imperial domus built with walls in opus vittatum and petit appareil is built west of the previous structure. The domus consists of a series of rooms organized around a central square space, possibly an atrium or a peristyle. Floors decorated with mosaics and the presence of an apsidal room connected to the large central space are some of the main features of the domus, which, based on comparanda with other similar buildings of the Mediterranean, seems to have been built in the late 2nd or early 3rd century CE. During this phase, the eastern part of the complex appears to have functioned as an annex dedicated to agricultural production.`}</MDXTag>
<MDXTag name="p" components={components}>{`A series of changes made in the residential areas of the domus during the 3rd century CE, suggests that the building was repurposed for industrial or commercial functions, before gradually falling out of use in the 4th century CE. `}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/assets/dscn0222.jpg","alt":null,"title":"Water feature in Area I"}}></MDXTag></MDXTag></MDXTag>

export const _frontmatter = {"templateKey":"content-pages","title":"Area I","backgroundImage":"","key":"area-i","parentKey":"excavation-areas"};

  